$(function(){
    $('[data-nav-trigger]').on('click', function(){
        $(this).toggleClass('active');
        $('[data-nav]').fadeToggle(200);
    });

    $('[data-toggle]').on('click', function(){
        const target = $(this).data('toggle');
        $('[data-toggle-target=' + target + ']').slideToggle(200);
        return false;
    })

    $('.p-gnav-list-item').hover(
        function() {
            if (window.matchMedia( '(min-width: 769px)' ).matches) {
                //マウスカーソルが重なった時の処理
                $(this).find('ol').fadeIn(200);
                return false;
            }
        },
        function() {
            if (window.matchMedia( '(min-width: 769px)' ).matches) {
                //マウスカーソルが離れた時の処理
                $(this).find('ol').fadeOut(200);
                return false;
            }
        }
    );
});



$(function(){
    //スマホ時　アコーディオンメニュー
    $('.nav-open').each(function(){
        $(this).on('click',function(){
            if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
                $('+ ol',this).slideToggle();
                return false;
            }
        });
    });
});